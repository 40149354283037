.login {
   width : 100%;
}

.login-input {
   height : 40px
}

.login-logo {
   display : flex;
   width : 100%;
   height : auto;
   margin : 0 0 20px 0;
}
