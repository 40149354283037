.activ-einput > .content {
   display: flex;
   flex: 1;
   flex-direction: column;
   align-items: center;
}

.footer {
	height: 60px;
   display: flex;
   justify-content: flex-end;
	align-items: center;
}

.active-input {
   display: flex;
   flex-direction: column;
   flex: 1;
   border: 1px solid #ddd;
   margin-top: 2%;
   margin-bottom: 2%;
}

.progress-indicator{
   align-self: center;
}

.chevron {
	align-self: center;
	justify-self: center;
	cursor: pointer;
	height: 50px;
	width: 50px;
}

.formBody {
   overflow-Y : initial!important;
   flex-basis: auto!important;
}

.formBody * {
   flex-basis: auto!important;
}

.formHeader {
   min-height : min-content !important;
    height: auto;
    text-align: center;
}

.formHeader > h2{
    margin-bottom: 10px;
    margin-top: 10px;
}

.exp-download {
   display: flex;
   width: 100%;
   height : 50px;
   align-items : center;
   justify-content: flex-start;
   font-size: 1.3em;
   padding-left: 50px;
   cursor: pointer;
   position: relative;
   text-align: left;
   border-bottom: 1px solid #ccc;
}

.exp-download:hover, .exp-download:active {
   background: '#eee';
}
